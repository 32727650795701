import React, { MouseEvent } from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';

import promoImg1 from '../../media/images/promo-1.png';
import promoImg2 from '../../media/images/promo-2.png';
import promoImg3 from '../../media/images/promo-3.png';
import promoImg4 from '../../media/images/promo-4.png';
import { PromoBannerItem } from '../PromoBannerItem/PromoBannerItem';

import './PromoBanner.css'

interface IItem {
  image: string;
  promoBannerType?: PromoBannerTypes;
}

export enum PromoBannerTypes {
  Long = 'long',
  Light = 'light',
}

interface IPromoBanner {
  className?: string
}

const items: IItem[] = [
  { image: promoImg1 },
  { image: promoImg2, promoBannerType: PromoBannerTypes.Long },
  { image: promoImg2, promoBannerType: PromoBannerTypes.Light },
  { image: promoImg3 },
  { image: promoImg4 },
];

const link = 'http://maxim.sds.am/lp/flow/b_am-vivacell-maxim?lpid=14';

export function PromoBanner({className}: IPromoBanner) {
  const promoBanner: ClassNameFormatter = cn('PromoBanner');

  const randomItem = items[Math.floor(Math.random() * items.length)];

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    window.location.replace(link);
  };

  return (
    <div onClick={handleClick} className={classnames(promoBanner(), className)}>
      {randomItem ? (
        <PromoBannerItem
          image={randomItem.image}
          promoBannerTypes={randomItem.promoBannerType}
        />
      ) : null}
    </div>
  );
}
