import React, {Component, KeyboardEvent, PureComponent, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {Translation} from 'react-i18next';
import Swiper from 'react-id-swiper';
import {Link} from 'react-router-dom';

import {PRESS_ALIAS, TEXTCONTENT_URL_BASE} from '../../../constants/constants';
import {ICategory, IPreview, Languages, PageType} from '../../../types/types';
import {ErrorPage} from '../../ErrorPage/ErrorPage';
import {HeaderContainer} from '../../Header/HeaderContainer';
import {HorizontalPreloader} from '../../Preloader/HorizontalPreloader';

import './PressContentPage.css';

export interface IPressContentPageStateProps extends IClassNameProps {
    name: string;
    month: string;
    firstPagePreview: IPreview;
    hash: string;
    routeUrl: string;
    categoriesData: ICategory[];
    manifest: string[];
    manifestLoaded: boolean;
    manifestError: boolean;
    language: Languages;
}

export interface IPressContentPageDispatchProps {
    onLoad(hash: string, routeUrl: string): void;
}

export interface IPressContentPageProps extends IPressContentPageStateProps, IPressContentPageDispatchProps {
}

interface ISimpleSlideProps {
    className?: string;
    text: string;
    isActive: boolean;
    hash: string;
}

interface ISliderProps {
    manifest: string[];
    hash: string;
}

const pressContentPage: ClassNameFormatter = cn('PressContentPage');

class SimpleSlide extends PureComponent<ISimpleSlideProps> {
    getPdfPage = (text: string, hash: string) => {
        const pdfPageUrl = `${TEXTCONTENT_URL_BASE}${hash}/${text}`;
        return (
            <img src={pdfPageUrl} className={pressContentPage('Image')}/>
        )
    };

    render() {
        const {className, isActive, text, hash} = this.props;

        const classes = [className, 'slide'].join(' ');
        const content = isActive ? this.getPdfPage(text, hash) : text;

        return (
            <div className={classes}>
                {content}
            </div>
        )
    }
}

class Slider extends PureComponent<ISliderProps, { index: number, inputValue: string}> {
    state: { index: number, inputValue: string } = {
        index: 0,
        inputValue: '1',
    };

    choosePage = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.charCode === 13) {
            const targetPage = Number(this.state.inputValue);
            if (!isNaN(targetPage) && targetPage > 0 && targetPage <= this.props.manifest.length) {
                this.setState({index: targetPage - 1})
            }
        } else {
            this.setState({inputValue: e.currentTarget.value})
        }
    };

    onInputChange = (e: {target: HTMLInputElement}) => {
        this.setState({inputValue: e.target.value})
    };

    render() {
        const {index} = this.state;
        const {hash, manifest} = this.props;
        const ss = (i: number) => this.setState({index: i, inputValue: String(i + 1)});
        const slides = manifest.map((elem: string, i: number) => (
            <SimpleSlide
                key={`${i}`}
                isActive={Math.abs(index - i) < 2}
                text={elem}
                hash={hash}
            />
        ));
        const events = {
            slideChange(this: { activeIndex: number; }) {
                ss(this.activeIndex);
            },
        };
        const params = {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            spaceBetween: 30,
        };

        return (
            <div className={pressContentPage('SwiperWrapper')}>
                <Swiper {...params} on={events} activeSlideKey={String(this.state.index)}>{slides}</Swiper>
                <p className={pressContentPage('Paginator')}>
                    <Translation>
                        {
                            (t, { i18n }) =>
                                <span className={pressContentPage('PaginatorText')}>{t('Страница')}</span>
                        }
                    </Translation>
                    <span className={pressContentPage('CurrentPageInput')}>
                        {this.state.inputValue}
                    </span>
                    <Translation>
                        {
                            (t, { i18n }) =>
                                <span className={pressContentPage('PaginatorText')}>{t('из')}</span>
                        }
                    </Translation>
                    {manifest.length}</p>
            </div>
        );
    }
}

export class PressContentPage extends Component<IPressContentPageProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.manifest === undefined) {
            this.props.onLoad(this.props.hash, this.props.routeUrl);
        }
    }

    renderSlider = (manifest: string[], hash: string): ReactNode => {
        if (this.props.manifestError && !this.props.manifestLoaded) {
            return <ErrorPage/>
        } else if (this.props.manifest !== undefined && this.props.manifestLoaded) {
            // const url = 'http://localhost:3000/storage/d/4cc18991ef3c4b08976d368e77a1c228';
            return (
                <Slider manifest={manifest} hash={hash}/>
            )
        } else if (!this.props.manifestLoaded) {
            return (<HorizontalPreloader/>);
        }
    };

    render() {
        const {hash, manifest, name} = this.props;
        return (
            <div className={pressContentPage()}>
                <HeaderContainer
                    className={pressContentPage('Header')}
                    categoriesData={this.props.categoriesData}
                    page={PageType.typePress}
                    onChange={(lang: Languages) => {
                    }}/>
                <div className={pressContentPage('ContentWrapper')}>
                    <div className={pressContentPage('Item')}>
                        <div className={pressContentPage('ItemTitleWrapper')}>
                            <Link to={'/category/press'} className={pressContentPage('CategoryLink')}/>
                            <Link to={`/category/${PRESS_ALIAS}`} className={pressContentPage('CategoryLink')}/>
                            <div className={pressContentPage('ItemTitle')}>{name}</div>
                        </div>
                    </div>
                    {this.renderSlider(manifest, hash)}
                </div>
            </div>
        )
    }
}
