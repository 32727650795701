import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Link} from 'react-router-dom';

import {PRESS_ALIAS} from '../../../constants/constants';
import {getContentLink} from '../../../helpers/getLinks';
import {IContentInfo, IPreview} from '../../../types/types';

import './PressContentItem.css';

const pressContentItem: ClassNameFormatter = cn('PressContentItem');

export interface IPressContentItemStateProps {
    language: string;
    authorised?: boolean;
}

export interface IPressContentItemProps extends IClassNameProps, IPressContentItemStateProps {
    content: IContentInfo;
}

export class PressContentItem extends Component<IPressContentItemProps> {
    render() {
        const {content, authorised} = this.props;
        const link = getContentLink(PRESS_ALIAS, content.id);
        const preview = content.previews.filter((p: IPreview) => p.type.includes('normal'));
        return (
            <Link to={link} className={classnames(pressContentItem({premium: !authorised}), this.props.className)}>
                <div className={pressContentItem('ImageWrap')}>
                    <img
                        data-src={`/storage/d/${preview[0].link}`}
                        className={classnames('swiper-lazy', pressContentItem('Image'))}
                        alt={''}
                    />
                    <div className='swiper-lazy-preloader'/>
                </div>
            </Link>
        )
    }
}
