import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {COMMON_CONTENT_CARD_TYPE} from '../../constants/constants';
import {IContentCard} from '../../helpers/contentHelper';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';

import './ContentListPartial.css';

export interface IContentListPartial extends IClassNameProps {
    cards: IContentCard[];
    alias: string;
    page: string;
}

const contentListPartial: ClassNameFormatter = cn('ContentListPartial');

export class ContentListPartial extends Component <IContentListPartial> {

    getContentItems = (content: IContentCard[]) => {
        const {alias} = this.props;

        return (
            content.map((contentItem: IContentCard, index) => {
                const {cardType, data} = contentItem;
                let size: string;
                index < 4 ? size = 'big' : size = 'small';
                if (cardType === COMMON_CONTENT_CARD_TYPE) {
                    const [contentData] = data;

                    return (

                    <ContentItemPreviewContainer className={contentListPartial('Item', {size})}
                                        id={contentData.id}
                                        key={contentData.id}
                                        title={contentData.name}
                                        description={contentData.description}
                                        tags={contentData.tags}
                                        order={index}
                                        previews={contentData.previews}
                                        type={contentData.type}
                                        url={contentData.file_hash}
                                        categoryAlias={alias}
                                        page={this.props.page}
                                        uidx={contentData.uidx}
                                        size={size}
                                        />
                )}
            })
        )
    };

    render() {
        const {cards} = this.props;

        return(
            <div className={contentListPartial()}>
                <div className={contentListPartial('ItemsWrapper')}>
                    {this.getContentItems(cards)}
                </div>
            </div>
        )
    }

}
