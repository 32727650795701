import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {ICategory, Languages, PageType} from '../../types/types';
import {CategoryList} from '../CategoryList/CategoryList';
import {Header} from '../Header/Header';
import {HeaderContainer} from '../Header/HeaderContainer';

import './MainPage.css';

export interface IMainPageProps extends IClassNameProps {
    categoriesData?: ICategory[];
    handlePlay(): void;
    handlePause(): void;
}

const mainPage: ClassNameFormatter = cn('MainPage');

export class MainPage extends Component<IMainPageProps> {
    constructor(props: IMainPageProps) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {categoriesData, handlePlay, handlePause} = this.props;
        if (categoriesData) {
            return (
                <section className={mainPage()}>
                    <HeaderContainer
                        className={mainPage('Header')}
                        categoriesData={categoriesData}
                        page={PageType.typeMain}
                        onChange={(lang: Languages) => {
                        }}/>
                    <section className={mainPage('Content')}>
                        <CategoryList
                            className={mainPage('CategoryListPage')}
                            categoriesData={categoriesData}
                            handlePlay={handlePlay}
                            handlePause={handlePause}
                        />
                    </section>
                </section>
            )
        }

        return null;
    }
}
